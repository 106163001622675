import { ErrorMessage, Field } from "formik";

const InputField = ({ label, name, placeholder, ...props }: any) => {
  return (
    <div className="font-['Lato'] font-normal space-y-1">
      <label className="capitalize" htmlFor={name}>
        {label}
      </label>
      <Field
        name={name}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full px-3 p-2.5 outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder={placeholder}
        {...props}
      />
      <p className="text-red-400 text-sm pt-1">
        <ErrorMessage name={name} />
      </p>
    </div>
  );
};

export default InputField;
