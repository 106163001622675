import { useState } from "react";
import { navigation } from "../../routes/navigation";
import classNames from "../../utils/classes";
import PhoneNumber from "../atoms/PhoneNumber";

const Navigation = ({ open, setOpen }: { open: boolean; setOpen: any }) => {
  const [isActive, setIsActive] = useState("#home");

  return (
    <div
      className={classNames(
        "bg-sudokkhoDarktGreen lg:block",
        open ? "block" : "hidden"
      )}
    >
      <div className="flex justify-between items-center flex-col lg:flex-row space-y-5 lg:space-y-0 py-5 lg:py-2 container">
        <div />
        <ul className="block lg:flex items-center lg:space-x-16 space-y-4 lg:space-y-0 text-center">
          {navigation.menu.map((item) => (
            <li key={item.path}>
              <a
                className={classNames(
                  "block border-2 py-1 px-5 text-sudokkhoTitleBlack rounded-md hover:bg-sudokkhoWhite hover:border-transparent cursor-pointer",
                  isActive === item.path &&
                    "text-sudokkhoTitleBlack border-transparent bg-sudokkhoWhite"
                )}
                href={item.path}
                onClick={() => {
                  setIsActive(item.path);
                  setOpen(false);
                }}
              >
                {item.title}
              </a>
            </li>
          ))}
        </ul>
        <PhoneNumber phone={navigation.phone} />
      </div>
    </div>
  );
};

export default Navigation;
