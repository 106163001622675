import Paragraph from "../atoms/Paragraph";
import SubTitle from "../atoms/SubTitle";
type props = {
  title: string;
  subTitle: string;
  image: string;
};

const WhatIsShudokkhoVET = ({ title, subTitle, image }: props) => (
  <div className="py-20" id="what-is-sudokkho">
    <div className="container space-y-10 lg:space-y-20">
      <div className="text-center space-y-4">
        <SubTitle>{title}</SubTitle>
        <Paragraph>{subTitle}</Paragraph>
      </div>
      <div className="max-w-5xl mx-auto">
        <img
          loading="lazy"
          src={image}
          alt="what is sudokkho vet"
          className="w-full"
        />
      </div>
    </div>
  </div>
);

export default WhatIsShudokkhoVET;
