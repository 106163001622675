import ListTitle from "../atoms/ListTitle";

type props = {
  title: string;
  list: string[];
};
const ArrowListData = ({ title, list }: props) => (
  <div className="w-full lg:w-1/2">
    <div className="space-y-8">
      <ListTitle>{title}</ListTitle>
      <ul className="space-y-8 pr-3">
        {list.map((item, index) => (
          <li className="flex items-start gap-3" key={index}>
            <img src="/assets/img/right-arrow.png" alt="right-arrow" />
            <p>{item}</p>
          </li>
        ))}
      </ul>
    </div>
  </div>
);
export default ArrowListData;
