const PlusIcon = () => (
  <svg
    width={45}
    height={46}
    viewBox="0 0 45 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.7522" cy="22.9871" r="22.0779" fill="#ACACAC" />
    <path d="M19.5104 23.0199H25.9854V25.5949H19.5104V23.0199Z" fill="white" />
  </svg>
);
export default PlusIcon;
