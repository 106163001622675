import useModal from "../../hooks/useModal";
import HeroLeftImageContent from "../atoms/HeroLeftImageContent";
import Modal from "../molecules/Modal";
import HeroRightTextContent from "../organisms/HeroRightTextContent";
type props = {
  title: string;
  subTitle: string;
  description: string;
  image: string;
};

const HeroSection = ({ image, title, subTitle, description }: props) => {
  const { show, handleClose, handleShow } = useModal();
  return (
    <div
      id="home"
      style={{
        background:
          "linear-gradient(360deg, #D1E2AB 0%, rgba(209, 226, 171, 0) 134.78%)",
      }}
      className="min-h-[400px] flex items-center py-16"
    >
      <div className="container">
        <div className="flex items-center flex-col lg:flex-row gap-10 lg:gap-44">
          <HeroLeftImageContent imageSrc={image} />
          <HeroRightTextContent
            title={title}
            subTitle={subTitle}
            description={description}
            handleShow={handleShow}
          />
        </div>
      </div>
      {show && <Modal show={show} handleClose={handleClose} />}
    </div>
  );
};

export default HeroSection;
