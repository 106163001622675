const MOCKS = {
  hero: {
    image: "/assets/img/farmer-hero-image.svg",
    title: "Shudokkho",
    subTitle: "Telemedicine Service",
    description: `Are you a Veterinarian? Join our Network of Veterinarians to Change Health Care Landscape of Livestock in Bangladesh.`,
  },
  whatIsShudokkhoVET: {
    title: "What is SHUDOKKHO-VET?",
    subTitle: "Shudokkho-Vet is a telemedicine service for livestock",
    image: "/assets/img/what-is-sudokkho.svg",
  },
  shudokkhoMobileApp: {
    title:
      "Any farmer from anywhere in the country can avail expert veterinary consultation for his/her cattle using SHUDOKKHO mobile app.",
    image: "/assets/img/app-image.png",
  },
  whyShudokkhoVET: {
    title: "Why SHUDOKKHO-VET?",
    sections: [
      {
        background:
          "linear-gradient(30.27deg, #ABF5FF 19.54%, rgba(171, 213, 226, 0) 98.21%)",
        image: "/assets/img/farmer-hero-image.svg",
        item: {
          title: "Farmers",
          list: [
            "Expert opinion from the best veterinarians in the country",
            "On demand 24/7 service",
            "Recorded prescriptions for future reference",
            "Relatively low cost",
            "Healthy cattle, higher productivity and more income",
          ],
        },
      },
      {
        background:
          "linear-gradient(30.27deg, #ABFFF0 19.54%, rgba(209, 226, 171, 0) 98.21%)",
        image: "/assets/img/doctor.svg",
        item: {
          title: "Veterinarians",
          list: [
            "Wider coverage with shorter time around time for service delivery",
            "More income",
          ],
        },
      },
      {
        background:
          "linear-gradient(30.27deg, #ABE1FF 19.54%, rgba(203, 243, 255, 0) 98.21%)",
        image: "/assets/img/visitor.svg",
        item: {
          title: "LSPs",
          list: ["Access to the best veterinarians for referral"],
        },
      },
      {
        background:
          "linear-gradient(30.27deg, #FFE7AB 19.54%, rgba(255, 249, 228, 0) 98.21%)",
        image: "/assets/img/cow.svg",
        item: {
          title: "Nation",
          list: ["Higher productivity of livestock", "Greater food security"],
        },
      },
    ],
  },
  info: "Most of our cattle population remains outside the scope of professional veterinary service. But we can leverage technology to connect a farmer with a distantly located veterinarian. This is exactly why we brought SHUDOKKHO-VET service.",
  whyToUseShodokkho: {
    title: "As a farmer why would you use SHUDOKKHO-VET",
    items: [
      {
        text: "You will get high quality professional veterinarian service at your fingertip",
        image: "/assets/img/bag-icon.svg",
      },
      {
        text: "SHUDOKKHO-VET is an on-demand service available 24/7",
        image: "/assets/img/time-icon.svg",
      },
      {
        text: "The prescriptions will be recorded for future reference",
        image: "/assets/img/prescription-icon.svg",
      },
      {
        text: "The cost is a fraction of the cost for availing traditional veterinary service",
        image: "/assets/img/money-icon.svg",
      },
    ],
  },
  meetOurVeterinan: {
    title: "Meet our veterinarians ",
    items: [
      {
        image: "/assets/img/veterinarians/Dr. Kaosar.png",
        name: "DR. MIR KAUSER HOSSAIN",
        designation: "Veterinary Surgeon",
        email: "Jhenaidah",
        mobile: "BVC Reg no: 3991",
      },
      {
        image: "/assets/img/veterinarians/DR. Jahid.jpg",
        name: "DR. MD. JAHID-AL-HASAN",
        designation: "Veterinary Surgeon",
        email: "PABNA",
        mobile: "BVC Reg no: 4855",
      },
      {
        image: "/assets/img/veterinarians/DR. Tapu.jpg",
        name: "DR. TAPU RAYHAN",
        designation: "Deputy General Manager",
        email: "PRAN-RFL Animal Feed Division",
        mobile: "BVC Reg no: 4906",
      },
      {
        image: "/assets/img/veterinarians/DR. Mumin.png",
        name: "Dr. Md. Mominul Islam",
        designation: "Senior Market Extension Officer",
        email: "Quality Feeds Limited",
        mobile: "BVC Reg no: 5250",
      },
      {
        image: "/assets/img/veterinarians/DR. Forhad 1.png",
        name: "DR. MD. FARHAD HOSSAIN",
        designation: "Ex Director, Research, Training and Evaluation",
        email: "Department of Livestock Services, Dhaka, Bangladesh. ",
        mobile: "BVC Reg no: 0299",
      },
      {
        image: "/assets/img/veterinarians/DR. SADDAM.png",
        name: "DR. MD. SADDAM HOSSAIN",
        designation: "Managing Director",
        email: "Birds and Pet Animal Clinic",
        mobile: "BVC Reg no: 4855",
      },
      {
        image: "/assets/img/veterinarians/Dr. Tareq 1.png",
        name: "DR. MD. TARQUL ISLAM",
        designation: "Operation Coordinator",
        email: "mPower Social Enterprise Limited",
        mobile: "BVC Reg no: 4771",
      },
      {
        image: "/assets/img/veterinarians/DR. Surovi.png",
        name: "DR. JANNATUL FERDOUS",
        designation: "Senior Lab Technicians",
        email: "Nahar Agro Industries Limited",
        mobile: "BVC Reg no: 4464",
      },
    ],
  },
  faq: {
    title: "Frequently asked questions (FAQ)",
    items: [
      {
        title:
          "How different is this app from the traditional treatment service that we veterinarians provide? ",
        description:
          "Generally, our cattle population is very dispersedly located and a practicing veterinarian needs to travel long distances to provide treatment to a few cattle in a single day. This process is also time and money-consuming. With Shudokkho-Vet app, you can provide treatment service from the comfort of your home or any other place and a standard case should not take you more than 10-15 minutes. There would be no time barrier and you can provide service any time of a day.  ",
      },
      {
        title:
          "I want to join this platform to provide treatment services. What is the process? ",
        description:
          "Please download the app 'Shudokkho' from Google Play Store. Complete the registration as ‘Veterinary Doctor’ from ‘Create New Account’ option. There is a validation process from the Admin. Someone from the Admin will call you to validate some of the information you provided during the registration and give an orientation to the App. Once the validation is complete, you can use this platform and provide treatment services. You will only get service requests when you show yourself ‘Online’ in the app. There is an ‘Online/Offline’ button at the top right corner of the app. ",
      },
      {
        title: "Who will set my service fee?",
        description:
          "You can set your service fee from 'Your Profile' option. You can enter any amount you prefer. However, we recommend to set an amount that matches with your expertise, experience and time & effort for a service. It is also important to stay competitive since one of the considerations farmers have in selecting a veterinarian is the amount of service fee. You should also keep in mind the deductible 20% platform fee in setting your service fee. ",
      },
      {
        title: "Once the service fee is set, can I change it?  ",
        description: "You can change your service fee at any time.",
      },
      {
        title: "How do I get the service fee? How long will it take? ",
        description:
          "You will receive your service fee in your designated bank account at the end of every week and 20% of your total amount will be deducted as a platform fee. This platform fee includes mobile financial service cost, video calling platform cost, server maintenance and other operational costs.",
      },
      {
        title:
          "How can I get help if I face any technical problem with the app? ",
        description:
          "You can get any technical problem resolved just by calling our helpline number between 11:00 am to 7:00 pm as mentioned in the side menu bar of Shudokkho app.",
      },
      {
        title: "Who will take my service through the app? Farmers or LSPs?",
        description:
          "Farmers and LSPs both types of people will have the option to get your service through this app.",
      },
      {
        title:
          "Is there any specific timing for providing service through the app?",
        description:
          "There is no specific timing for providing service. You can set the time according to your convenience. But you have to keep yourself offline when you do not want to provide services. Otherwise, you may receive unwarranted service requests resulting in customer dissatisfaction and bad reviews. You will receive a notification right before your scheduled time reminding you that it is time for your online service.",
      },
      {
        title:
          "How will my clients know about my availability for providing services?",
        description:
          "Your clients will understand that you are ready to provide service only if you show yourself ‘Online’ in the app. A separate list of online and offline doctors is displayed within the client's app.",
      },
      {
        title: "Is it possible to provide service anywhere with this app?",
        description: "Yes! Services can be provided anywhere through this app.",
      },
      {
        title:
          "Let me say I want to provide services in certain areas. Is it possible to do that?",
        description:
          "Of course! If you want to provide service in a particular area, you can define that area from 'Your Profile' option and only the farmers and LSPs of that particular area can see you and send their cases.",
      },
      {
        title:
          "I can see an option to select regional dialect while creating my profile. What is the purpose of it? ",
        description:
          "Some farmers and LSPs might be comfortable having conversations in their regional dialect. If you are proficient in any regional dialect, we would request you to select that language while creating your profile. As a result, there is a higher chance that farmers or LSPs of that region will call you and get their problems resolved.",
      },
    ],
  },
};

export default MOCKS;
