const MinusIcon = () => (
  <svg
    width={45}
    height={46}
    viewBox="0 0 45 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.7522" cy="22.9871" r="22.0779" fill="#A6D6B0" />
    <path
      d="M23.9761 19.1949V24.3199H28.8386V26.6074H23.9761V31.7574H21.4761V26.6074H16.6386V24.3199H21.4761V19.1949H23.9761Z"
      fill="white"
    />
  </svg>
);
export default MinusIcon;
