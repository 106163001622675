import React, { ReactNode } from "react";
import classNames from "../../utils/classes";

type props = {
  children: ReactNode;
  className?: string;
};
const ListTitle: React.FC<props> = ({ children, className = "" }) => (
  <h4
    className={classNames(
      "text-2xl lg:text-3xl font-normal text-sudokkhoListTitleBlack",
      className
    )}
  >
    {children}
  </h4>
);

export default ListTitle;
