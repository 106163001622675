import Accordion from "../atoms/Accordion";
import Title from "../atoms/Title";

const Faqs = ({ title, items }: { title: string; items: any[] }) => (
  <div
    className="py-20 lg:py-40"
    style={{
      background:
        "linear-gradient(360deg, #ABE2DF 0%, rgba(209, 226, 171, 0) 134.78%)",
    }}
  >
    <div className="space-y-8 lg:space-y-20 container">
      <Title className="text-center">{title}</Title>

      <div className="space-y-4">
        <Accordion items={items} />
      </div>
    </div>
  </div>
);

export default Faqs;
