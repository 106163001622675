import { ReactNode } from "react";
import SubTitle from "./SubTitle";

const ModalHeader = ({
  handleClose,
  children,
}: {
  children: ReactNode;
  handleClose: () => void;
}) => (
  <div className="flex justify-between items-center">
    <SubTitle>{children}</SubTitle>
    <button onClick={handleClose}>
      <svg
        width={44}
        height={44}
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx={22} cy={22} r={22} fill="#D9D9D9" />
        <path
          d="M18 18.3525L26 25.6467"
          stroke="#474747"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path
          d="M25.6475 18L18.3533 26"
          stroke="#474747"
          strokeWidth={2}
          strokeLinecap="round"
        />
      </svg>
    </button>
  </div>
);

export default ModalHeader;
