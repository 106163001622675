const TopHeader = ({ setOpen }: { setOpen: any }) => (
  <div className="flex justify-between items-center h-[80px] lg:h-[119px] bg-sudokkhoLightGreen px-8 lg:px-14 border-b lg:border-b-0">
    <div>
      <img
        loading="lazy"
        src="/assets/img/mpower-logo.svg"
        alt="mPower logo"
        className="w-[96px] lg:w-[100px]"
      />
    </div>
    <div className="flex items-center space-x-3">
      <img
        loading="lazy"
        src="/assets/img/sudokkho-logo.svg"
        alt="mPower logo"
        className="w-[90px] lg:w-[106px]"
      />
      <button onClick={() => setOpen((prev: any) => !prev)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 block lg:hidden"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </button>
    </div>
    <div className="hidden lg:block" />
  </div>
);

export default TopHeader;
