import React, { ReactNode } from "react";
import classNames from "../../utils/classes";

type props = {
  children: ReactNode;
  className?: string;
};
const Paragraph: React.FC<props> = ({ children, className = "" }) => (
  <h3
    className={classNames("font-normal text-sudokkhoParagraphGray", className)}
  >
    {children}
  </h3>
);

export default Paragraph;
