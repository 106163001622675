const Info = ({ info }: { info: string }) => (
  <div
    className="py-20 lg:py-32"
    style={{
      background:
        "linear-gradient(30.27deg, #F5F5F5 19.54%, rgba(255, 249, 228, 0) 98.21%)",
    }}
  >
    <div className="container">
      <p className="text-center">{info}</p>
    </div>
  </div>
);

export default Info;
