import Faqs from "./components/molecules/Faqs";
import Info from "./components/molecules/Info";
import MeetOurVeterinarian from "./components/molecules/MeetOurVeterinarian";
import WhyToUseShodokkho from "./components/molecules/WhyToUseShodokkho";
import ShudokkhoMobileApp from "./components/organisms/ShudokkhoMobileApp";
import WhatIsShudokkhoVET from "./components/organisms/WhatIsShudokkhoVET";
import HeroSection from "./components/templates/HeroSection";
import WhyShudokkhoVET from "./components/templates/WhyShudokkhoVET";
import MOCKS from "./data/mocks";
import Layouts from "./layouts/Layouts";
const {
  hero,
  whatIsShudokkhoVET,
  shudokkhoMobileApp,
  whyShudokkhoVET,
  info,
  whyToUseShodokkho,
  meetOurVeterinan,
  faq,
} = MOCKS;

const App = () => (
  <Layouts>
    <HeroSection {...hero} />
    <WhatIsShudokkhoVET {...whatIsShudokkhoVET} />
    <ShudokkhoMobileApp {...shudokkhoMobileApp} />
    <WhyShudokkhoVET {...whyShudokkhoVET} />
    <Info info={info} />
    <WhyToUseShodokkho {...whyToUseShodokkho} />
    <MeetOurVeterinarian {...meetOurVeterinan} />
    <Faqs {...faq} />
  </Layouts>
);

export default App;
