import { useState } from "react";
import classNames from "../../utils/classes";
import MinusIcon from "./MinusIcon";
import PlusIcon from "./PlusIcon";

const Accordion = ({ items }: { items: any[] }) => {
  const [open, setOpen] = useState(0);
  return (
    <>
      {items.map((item, index) => (
        <div key={index}>
          <div
            className={classNames(
              "bg-sudokkhoWhite py-5 px-6 rounded-lg border flex justify-between items-center shadow-md drop-shadow-md"
            )}
          >
            <p className={classNames(open === index && "font-semibold")}>
              {item.title}
            </p>
            <button onClick={() => setOpen(index === open ? 99999 : index)}>
              {open === index ? <PlusIcon /> : <MinusIcon />}
            </button>
          </div>
          {open === index && (
            <div className="bg-sudokkhoWhite py-5 px-6 rounded-lg border flex justify-between items-center shadow-md drop-shadow-md">
              <p>{item.description}</p>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default Accordion;
