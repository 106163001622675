import Title from "../atoms/Title";
import VeterinarianCard from "../atoms/VeterinarianCard";

const MeetOurVeterinarian = ({
  title,
  items = [],
}: {
  title: string;
  items: any[];
}) => (
  <div className="pb-20">
    <div className="container space-y-20">
      <Title className="text-center">{title}</Title>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-8">
        {items.map((item, index) => (
          <VeterinarianCard key={index} {...item} />
        ))}
      </div>
    </div>
  </div>
);

export default MeetOurVeterinarian;
