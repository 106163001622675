import classNames from "../../utils/classes";
import SubTitle from "../atoms/SubTitle";
import ArrowListData from "../molecules/ArrowListData";
type props = {
  title: string;
  sections: any[];
};

const WhyShudokkhoVET = ({ title, sections }: props) => (
  <div id="why-sudokkho">
    <div>
      <SubTitle className="text-center py-20">{title}</SubTitle>
    </div>
    <div className="space-y-5">
      {sections.map((listItem, index) => (
        <div
          className="py-8 lg:py-10"
          style={{
            background: listItem.background,
          }}
          key={index}
        >
          <div className="container max-w-7xl">
            <div className="flex flex-col lg:flex-row gap-10 items-center">
              <div
                className={classNames(
                  "w-full lg:w-1/2",
                  index % 2 !== 0 && "lg:order-2"
                )}
              >
                <img
                  src={listItem.image}
                  alt={listItem.item.title}
                  className={classNames(
                    "max-w-xs mx-auto",
                    index % 2 !== 0 && "lg:mx-auto"
                  )}
                />
              </div>
              <ArrowListData
                title={listItem.item.title}
                list={listItem.item.list}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default WhyShudokkhoVET;
