import { useState } from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import baseURL from "../configs/baseUrl";

export const formatError = (error: any) => {
  if (error?.response?.data?.message) {
    return error.response.data.message;
  }
  return error.message;
};

export const fireAlert = (callback: () => void) => {
  Swal.fire({
    html: "Thanks for your interest in joining our platform. Please expect a call from us soon.",
    timer: 4000,
    timerProgressBar: true,
    didOpen: () => {
      Swal.showLoading();
      if (typeof callback === "function") {
        callback();
      }
    },
  });
};

const initialValue = {
  username: "",
  phone: "",
  email: "",
  bvc_reg_no: "",
  current_employer: "",
  designation: "",
  user_comment: "",
};

const schema = Yup.object().shape({
  username: Yup.string().required("Name is required"),
  phone: Yup.string().required("Phone number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  bvc_reg_no: Yup.string().required("BVC registration number is required"),
  designation: Yup.string().required("Position is required"),
  current_employer: Yup.string().required("Current Employer is required"),
});

const useClickToJoin = (handleClose: () => void) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const handleSubmit = (data: any) => {
    setIsLoading(true);
    fetch(baseURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then(() => {
        fireAlert(handleClose);
      })
      .catch((err) => {
        setError(err.message ?? formatError(err));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return { isLoading, error, handleSubmit, initialValue, schema };
};

export default useClickToJoin;
