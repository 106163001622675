import { useState } from "react";

interface ModalState {
  show: boolean;
  handleClose: () => void;
  handleShow: () => void;
}

const useModal = (): ModalState => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return { show, handleClose, handleShow };
};

export default useModal;
