export const navigation = {
  menu: [
    {
      title: "Home",
      path: "#home",
    },
    {
      title: "What is Shudokkho",
      path: "#what-is-sudokkho",
    },
    {
      title: "Why Shudokkho",
      path: "#why-sudokkho",
    },
  ],
  phone: "01987-6543-21",
};
