import Paragraph from "../atoms/Paragraph";
type props = {
  title: string;
  image: string;
};

const ShudokkhoMobileApp = ({ image, title }: props) => (
  <div className="lg:pt-32">
    <div
      style={{
        background:
          "linear-gradient(30.27deg, #FFD361 19.54%, rgba(209, 226, 171, 0) 98.21%)",
      }}
    >
      <div className="flex flex-col items-center container gap-10 py-16 lg:flex-row lg:py-0 lg:h-96 lg:gap-20">
        <div className="lg:w-1/2 order-2 lg:order-1">
          <Paragraph className="text-sudokkhoTitleBlack text-center max-w-[500px]">
            {title}
          </Paragraph>
        </div>
        <div className="lg:w-1/2 order-1 lg:order-2">
          <img
            loading="lazy"
            src={image}
            alt=""
            className="lg:-mt-40 mx-auto w-[220px] lg:w-[393px]"
          />
        </div>
      </div>
    </div>
  </div>
);

export default ShudokkhoMobileApp;
