type props = {
  imageSrc: string;
};
const HeroLeftImageContent = ({ imageSrc }: props) => (
  <div className="lg:w-1/2">
    <img
      loading="lazy"
      src={imageSrc}
      alt="heroImage"
      width={390}
      height={320}
      className="w-full"
    />
  </div>
);

export default HeroLeftImageContent;
