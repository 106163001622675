const VeterinarianCard = ({
  image,
  name,
  designation,
  email,
  mobile,
}: {
  image: string;
  name: string;
  designation: string;
  email: string;
  mobile: string;
}) => (
  <div className="p-4 border rounded-sm font-['Lato']">
    <img
      src={image}
      className="w-full h-[230px] sm:h-[350px] object-cover object-center"
      alt={name}
    />
    <div>
      <div className="mt-3">
        <h6 className="font-semibold text-sm sm:text-base uppercase">{name}</h6>
        <p className="font-light">{designation}</p>
      </div>

      <div>
        <p className="font-light text-[12px] sm:text-[15px]">{email}</p>
        <p className="font-light text-[12px] sm:text-[15px]">{mobile}</p>
      </div>
    </div>
  </div>
);

export default VeterinarianCard;
