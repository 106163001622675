import Paragraph from "../atoms/Paragraph";
import SubTitle from "../atoms/SubTitle";
import Title from "../atoms/Title";
type props = {
  title: string;
  subTitle: string;
  description: string;
  handleShow: () => void;
};

const HeroRightTextContent = ({
  title,
  subTitle,
  description,
  handleShow,
}: props) => (
  <div className="space-y-4 text-center lg:text-start lg:w-1/2 lg:space-y-8">
    <div className="space-y-3">
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
    </div>
    <div className="space-y-4">
      <Paragraph className="leading-10">{description}</Paragraph>
    </div>
    <button
      onClick={handleShow}
      className="text-sudokkhoWhite bg-sudokkhoOrange py-2 px-10 font-semibold rounded-lg font-['Inter'] cursor-pointer hover:bg-sudokkhoOrange/70"
    >
      Click to join
    </button>
  </div>
);

export default HeroRightTextContent;
