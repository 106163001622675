import { Form, Formik } from "formik";
import useClickToJoin from "../../hooks/useClickToJoin";
import InputField from "../atoms/InputField";
import ModalHeader from "../atoms/ModalHeader";

const Modal = ({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: () => void;
}) => {
  const { isLoading, error, handleSubmit, initialValue, schema } =
    useClickToJoin(handleClose);

  return (
    <div className="absolute top-0 w-screen min-h-screen h-full">
      {show && (
        <div className="fixed w-full h-full bg-black/50 z-[999] flex md:items-center justify-center p-5 ">
          <div className="bg-sudokkhoWhite px-8 pt-8 pb-12  w-full max-w-5xl rounded-3xl space-y-5 my-6 overflow-auto modal-body">
            <ModalHeader handleClose={handleClose}>
              Join with us from here
            </ModalHeader>

            {error && (
              <div
                className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-100 dark:bg-gray-800 dark:text-red-400"
                role="alert"
              >
                {error}
              </div>
            )}

            <Formik
              initialValues={initialValue}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className="space-y-9">
                  <div className="space-y-5">
                    <fieldset
                      disabled={isLoading}
                      className="grid md:grid-cols-2 gap-x-24 gap-y-5"
                    >
                      <InputField
                        label="Name"
                        placeholder="John"
                        name="username"
                      />
                      <InputField
                        label="Phone Number"
                        placeholder="0987653647"
                        name="phone"
                      />
                      <InputField
                        label="Email Address"
                        placeholder="yourmail@email.com"
                        name="email"
                      />
                      <InputField
                        label="BVC Registration Number"
                        placeholder="16343h"
                        name="bvc_reg_no"
                      />
                      <InputField
                        label="Current employer"
                        placeholder="abcd"
                        name="current_employer"
                      />
                      <InputField
                        label="Position held"
                        placeholder="abcd"
                        name="designation"
                      />
                    </fieldset>
                    <fieldset disabled={isLoading}>
                      <InputField
                        label="Comment (if any)"
                        placeholder="abcd"
                        name="user_comment"
                        as="textarea"
                        rows={3}
                      />
                    </fieldset>
                  </div>
                  <fieldset
                    disabled={isLoading}
                    className="flex justify-between items-center max-w-sm mx-auto"
                  >
                    <button
                      onClick={handleClose}
                      className="text-sudokkhoWhite bg-[#C5C5C5] py-2 px-10 font-semibold rounded-lg font-['Inter'] cursor-pointer hover:bg-sudokkhoOrange/70 disabled:bg-sudokkhoOrange/70 disabled:cursor-not-allowed"
                    >
                      Cancel
                    </button>
                    <button
                      disabled={isLoading}
                      type="submit"
                      className="text-sudokkhoWhite bg-sudokkhoOrange py-2 px-10 font-semibold rounded-lg font-['Inter'] cursor-pointer hover:bg-sudokkhoOrange/70 disabled:bg-sudokkhoOrange/70 disabled:cursor-not-allowed"
                    >
                      Submit
                    </button>
                  </fieldset>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
